<template>
  <div class="slider-admin">
    <div class="mb-4">
      <v-card class="pa-6 mx-0">
        <v-row class="justify-space-between align-md-center">
          <v-col
            md="4"
            xl="4"
            cols="12"
            sm="12"
          >
            <h2>Slider</h2>
            <small style="white-space: normal;">Halaman untuk menambahkan slider di aplikasi</small>
          </v-col>
          <v-col
            md="4"
            sm="12"
            xl="4"
            cols="12"
          >
            <v-text-field
              v-model="search"
              label="Cari"
              outlined
              dense
              class="search"
              @change="searchHandler($event)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div>
      <v-row v-if="!isLoadingCard">
        <v-col
          v-for="(item,index) in form"
          :key="item.name"
          md="4"
          cols="12"
        >
          <a
            class="text-decoration-none"
            @click.prevent="showFormAdd(index)"
          >
            <v-card
              height="315"
              color="#F5F5F5"
              class="d-flex align-center justify-center"
              style="border: 1px dashed #c4c4c4;"
            >
              <div class="text-center">
                <v-icon size="40">
                  {{ icons.mdiPlus }}
                </v-icon>
                <p>Tambah Slider</p>
              </div>
            </v-card>
          </a>
        </v-col>
        <v-col
          v-for="(sliderItem,index) in sliders"

          :key="index"
          md="4"
          cols="12"
        >
          <v-card
            min-height="315"
            :loading="isLoadingImage"
          >
            <v-card
              class="overflow-hidden"
              style="z-index: 0"
            >
              <v-card-title
                class="pa-2 position-absolute"
                style="z-index: 1;right: 0;"
              >
                <v-menu
                  offset-y
                  nudge-bottom="5"
                  nudge-left="60"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="white"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <a @click.prevent="showFormEdit(sliderItem.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/edit.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2">Edit</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                    <a @click.prevent="confirmDestroy(sliderItem.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex btn-delete">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/trash-red.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2 text-delete">Hapus</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card
                style="z-index: 0;"
              >
                <div>
                  <v-img
                    :src="sliderItem.image"
                    height="150"
                  ></v-img>
                </div>
              </v-card>
              <v-overlay
                :absolute="true"
                z-index="0"
                opacity="0.25"
                color="#001A33"
              ></v-overlay>
              <v-chip
                class="position-absolute text-capitalize"
                style="top: 8px;left: 8px"
                color="primary"
              >
                {{ sliderItem.type }}
              </v-chip>
            </v-card>
            <v-card-text class="py-4">
              <div class="mb-4">
                <h2 class="font-weight-bold text-truncate mb-2">
                  {{ sliderItem.title }}
                </h2>
                <p class="font-weight-light text-truncate mb-2">
                  {{ sliderItem.description }}
                </p>
              </div>
              <div class="d-flex">
                <div class="me-2 mb-2">
                  <v-img
                    src="@/assets/icons/calendar.svg"
                    sizes="20"
                  ></v-img>
                </div>
                <span class="font-weight-light">{{ sliderItem.start_at }} - {{ sliderItem.end_at }} </span>
              </div>
              <div class="d-flex">
                <div class="me-2">
                  <v-img
                    src="@/assets/icons/clip.svg"
                    sizes="20"
                  ></v-img>
                </div>
                <span class="font-weight-light text-truncate mb-2">{{ sliderItem.link }}</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <infinite-loading
          :identifier="infiniteId"
          @infinite="infiniteHandler"
        >
          <div slot="no-results"></div>
          <div slot="no-more"></div>
        </infinite-loading>
      </v-row>
      <v-row v-else>
        <v-col
          v-for="i in 3"
          :key="i"
          md="4"
        >
          <v-skeleton-loader
            type="image"
            :loading="true"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || (slider.province_uuid === null && slider.regency_uuid === null && slider.school_uuid.length === 0) || slider.image === null"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Slider': 'Edit Slider'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-combobox
          v-model="slider.province_uuid"
          :items="provinces"
          item-text="name"
          item-value="uuid"
          return-object
          :loading="isLoadingData"
          label="Provinsi"
          outlined
          dense
          clearable
          :disabled="dialog ==='add'? regency_uuid !== null || slider.school_uuid.length >= 1 : slider.province_uuid === undefined"
          @change="getProvinceUuid($event)"
        >
        </v-combobox>

        <v-combobox
          v-model="slider.regency_uuid"
          :items="regencies"
          item-text="name"
          label="Kabupaten"
          outlined
          :disabled="dialog === 'add'? province_uuid !== null || slider.school_uuid.length >= 1 : slider.regency_uuid === undefined "
          dense
          :loading="isLoadingData"
          clearable
          @change="getRegencyUuid($event)"
        >
        </v-combobox>

        <v-combobox
          v-if="dialog==='add'"
          v-model="slider.school_uuid"
          :items="schools"
          :loading="isLoadingData"
          item-text="name"
          item-value="uuid"
          label="Pilih Sekolah"
          multiple
          outlined
          dense
          clearable
          :disabled="province_uuid !== null || regency_uuid !== null"
          small-chips
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>
                  </strong>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
        <v-combobox
          v-if="dialog==='edit'"
          v-model="slider.school_uuid"
          :items="schools"
          :loading="isLoadingData"
          item-text="name"
          item-value="uuid"
          label="Pilih Sekolah"
          multiple
          outlined
          dense
          clearable
          :disabled="province_uuid !== null || regency_uuid !== null || slider.school_uuid.length < 1 "
          small-chips
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>
                  </strong>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>

        <div class="mb-4">
          <FileInput
            v-if="dialog =='add'"
            :value="slider.image"
            label="Gambar"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          ></FileInput>
          <FileInput
            v-if="dialog =='edit'"
            v-model="initialFile"
            label="Gambar"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          ></FileInput>
        </div>
        <v-row>
          <v-col md="6">
            <v-menu
              v-model="start_date"
              :close-on-content-click="true"
              nudge-right="0"
              nudge-top="20"
              max-width="100%"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="slider.start_at"
                  label="Waktu Mulai"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  :rules="[validators.required]"
                  v-on="on"
                  @click="autofillStartDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="slider.start_at"
                :first-day-of-week="0"
                locale="id-id"
                color="primary"
                :min="new Date().toISOString().substr(0, 10)"
                :rules="[validators.required]"
                @input="start_date = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="6">
            <v-menu
              v-model="end_date"
              :close-on-content-click="true"
              nudge-right="0"
              nudge-top="20"
              max-width="100%"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="slider.end_at"
                  label="Waktu Selesai"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  :rules="[validators.required]"
                  v-on="on"
                  @click="autofillEndDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="slider.end_at"
                :first-day-of-week="0"
                locale="id-id"
                :min="new Date().toISOString().substr(0, 10)"
                color="primary"
                :rules="[validators.required]"
                @input="end_date = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <div>
          <v-text-field
            v-model="slider.link"
            label="Link Slider"
            outlined
            dense
            :rules="[validators.required]"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="slider.title"
            label="Judul"
            outlined
            dense
            :rules="[validators.required]"
          ></v-text-field>
        </div>
        <div>
          <v-textarea
            v-model="slider.description"
            label="Deskripsi"
            outlined
            dense
            :rules="[validators.required]"
          ></v-textarea>
        </div>
        <div>
          <p>Saat slider di klik :</p>
          <v-radio-group v-model="slider.choice">
            <v-radio
              v-for="(choice,index) in choices"
              :key="index"
              :label="choice.title"
              :value="choice.value"
              @change="handleChoice(choice.value)"
            ></v-radio>
          </v-radio-group>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Slider"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import FileInput from '@/views/components/FileInput.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import { required } from '@core/utils/validation'
import { mdiDotsVertical, mdiImageMultipleOutline, mdiPlus } from '@mdi/js'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Slider',
  components: {
    ModalDialog,
    ConfirmDialog,
    FileInput,
    InfiniteLoading,
  },
  data() {
    return {
      infiniteId: +new Date(),
      icons: {
        mdiPlus,
        mdiImageMultipleOutline,
        mdiDotsVertical,
      },
      validators: {
        required,
      },
      isLoadingCard: true,
      isLoadingButton: false,
      modalDialog: false,
      dialog: '',
      search: '',
      confirmDialog: false,
      service: 'slider',
      school_uuid: [],
      slider: {
        type: 'admin',
        school_uuid: [],
        province_uuid: null,
        regency_uuid: null,
        order: 0,
        title: '',
        image: null,
        start_at: '',
        end_at: '',
        link: '',
        description: '',
        choice: null,
      },
      choices: [
        { title: 'Halaman detail slider (dalam aplikasi)', value: 'page' },
        { title: 'Link ke browser (keluar aplikasi)', value: 'link' },
      ],
      sliders: [],
      form: [
        {
          title: '',
          image: null,
          start_at: '',
          end_at: '',
          link: '',
          description: '',
        },
      ],
      start_date: false,
      end_date: false,
      page: 1,
      schools: [],
      provinces: [],
      regencies: [],
      isLoadingData: true,
      isLoadingImage: true,
      totalItemsLib: 0,
      totalPagesLib: 100,
      pageLib: 1,
      uuid: [],
      admin_uuid: '',
      user: [],
      province_uuid: null,
      regency_uuid: null,
      types: [
        {
          name: 'Publik',
        },
        {
          name: 'Provinsi',
        },
        {
          name: 'Kabupaten',
        },
        {
          name: 'Sekolah',
        },
      ],
      isAdmin: null,
      formValid: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.slider.image === 'string') {
          return new File([this.slider.image], this.slider.image, { type: 'text/plain' })
        }

        return this.slider.image
      },
      set() {
        return this.slider.image
      },
    },
  },
  watch: {
    slider: {
      handler() {
        const valid = []
        const requiredField = ['title', 'description', 'start_at', 'end_at', 'choice']
        Object.entries(this.slider).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    search: {
      handler() {
        this.getSlider()
        this.isLoadingCard = false
      },
    },
    sliders: {
      handler() {
        this.offsetY = window.pageYOffset
      },
    },
  },
  async mounted() {
    await this.getUuid()
    await this.getSlider()
    this.listSchool()
    this.listProvince()
    this.listRegency()
    this.isLoadingCard = false
  },
  methods: {
    refreshInfiniteLoading() {
      this.pageLib = 1
      // as said in the documentation
      // "The infinite loading component will
      // reset itself whenever the identifier property has changed"
      this.infiniteId += 1
    },
    async infiniteHandler($state) {
      this.pageLib += 1

      await this.$services.ApiServices.list(this.service, {
        search: this.search,
        page: this.pageLib,
        per_page: 5,
        is_admin: 1,
      }).then(({ data }) => {
        if (data.data.length) {
          this.sliders = [...this.sliders, ...data.data]
          this.isLoadingCard = false
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    async getUuid() {
      this.user = JSON.parse(localStorage.getItem('user')).user.details
      this.user.forEach(el => {
        this.admin_uuid = el.uuid
      })
    },
    async listSchool(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('school', { ...params, page: this.page, per_page: 100 }).then(
        ({ data }) => {
          this.schools = data.data
          // this.totalItemsLib = data.meta.total
          // this.totalPagesLib = data.meta.per_page
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },
    async getSlider(params = {}) {
      await this.$services.ApiServices.list(this.service, {
        ...params,
        search: this.search,
        per_page: 5,
        is_admin: 1,
      }).then(({ data }) => {
        this.sliders = data.data
        this.totalItemsLib = data.meta.total
        this.totalPagesLib = data.meta.per_page
      })
      this.isLoadingImage = false
    },

    async listProvince(params = {}) {
      await this.$services.ApiServices.list('province', { ...params, per_page: 'all' }).then(
        ({ data }) => {
          this.provinces = data.data
        },
        err => console.error(err),
      )
    },
    async listRegency(params = {}) {
      await this.$services.ApiServices.list('regency', { ...params, per_page: 'all' }).then(
        ({ data }) => {
          this.regencies = data.data
        },
        err => console.error(err),
      )
    },

    resetForm() {
      this.slider.image = null
      this.slider.start_at = ''
      this.slider.end_at = ''
      this.slider.link = ''
      this.slider.title = ''
      this.slider.description = ''
      this.slider.province_uuid = null
      this.slider.regency_uuid = null
      this.slider.school_uuid = []
      this.slider.choice = null
      this.province_uuid = null
      this.regency_uuid = null
    },
    async showFormAdd(index) {
      await this.resetForm()
      this.dialog = 'add'
      this.modalDialog = true
      this.slider.order = this.sliders.length + 1
    },

    getProvinceUuid(event) {
      this.province_uuid = event?.uuid ?? null
    },
    getRegencyUuid(event) {
      this.regency_uuid = event?.uuid ?? null
    },

    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()

      if (this.province_uuid !== null) {
        this.formData.append('province_uuid', this.province_uuid)
      }
      if (this.regency_uuid !== null) {
        this.formData.append('regency_uuid', this.regency_uuid)
      }
      this.formData.append('image', this.slider.image)
      this.formData.append('title', this.slider.title)
      this.formData.append('order', this.slider.order)
      this.formData.append('link', this.slider.link)
      this.formData.append('description', this.slider.description)
      this.formData.append('start_at', this.slider.start_at)
      this.formData.append('end_at', this.slider.end_at)
      this.formData.append('is_admin', 1)
      if (this.province_uuid !== null || this.regency_uuid !== null || this.slider.school_uuid.length < 1) {
        this.formData.append('type', 'admin')
        this.formData.append('type_uuid[]', this.admin_uuid)
      } else if (this.slider.school_uuid) {
        this.formData.append('type', 'school')
        this.slider.school_uuid.forEach(item => {
          this.formData.append('type_uuid[]', item.uuid)
        })
      }
      if (this.slider.choice === null) {
        this.formData.append('choice', 'page')
      } else {
        this.formData.append('choice', this.slider.choice)
      }
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.pageLib = 1
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.refreshInfiniteLoading()
      await this.getSlider()

      this.province_uuid = null
      this.regency_uuid = null
      this.isLoadingButton = false
      this.modalDialog = false
      this.resetForm()
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.slider.uuid = uuid
      this.modalDialog = true
      const newData = []
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.slider = data.data
          data.data.details.forEach(el => {
            this.slider.province_uuid = el.province_name
            this.province_uuid = el.province_uuid
            this.slider.regency_uuid = el.regency_name
            this.regency_uuid = el.regency_uuid
          })

          this.schools.filter(school => {
            data.data.details.forEach(item => {
              if (school.uuid === item.uuid) newData.push(item)
            })

            return true
          })
          // this.slider.school_uuid = newData
          this.$set(this.slider, 'school_uuid', newData)
        },
        err => {
          console.error(err)
        },
      )
    },
    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      if (this.province_uuid !== undefined) {
        this.formData.append('province_uuid', this.province_uuid)
      }
      if (this.regency_uuid !== undefined) {
        this.formData.append('regency_uuid', this.regency_uuid)
      }

      this.formData.append('title', this.slider.title)
      this.formData.append('order', this.slider.order)
      this.formData.append('link', this.slider.link)
      this.formData.append('description', this.slider.description)
      this.formData.append('start_at', this.slider.start_at)
      this.formData.append('end_at', this.slider.end_at)
      this.formData.append('image', this.slider.image)
      this.formData.append('is_admin', 1)
      if (typeof this.slider.image === 'string') {
        this.formData.delete('image')
      }
      if (this.province_uuid !== null || this.regency_uuid !== null || this.slider.school_uuid.length < 1) {
        this.formData.append('type', 'admin')
        this.formData.append('type_uuid[]', this.admin_uuid)
      } else if (this.slider.school_uuid) {
        this.formData.append('type', 'school')
        this.slider.school_uuid.forEach(item => {
          this.formData.append('type_uuid[]', item.uuid)
        })
      }
      if (this.slider.choice === null) {
        this.formData.append('choice', 'page')
      } else {
        this.formData.append('choice', this.slider.choice)
      }
      await this.$services.ApiServices.update(this.service, this.formData, this.slider.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.getSlider()
      this.isLoadingButton = false
      this.modalDialog = false
      this.resetForm()
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.slider.title = data.data.title
      })
      this.slider.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.slider.uuid).then(({ data }) => {
        this.showSnackbar({
          text: data.result.message,
          color: 'error',
        })
      })
      this.isLoadingButton = false
      this.confirmDialog = false
      this.refreshInfiniteLoading()
      await this.getSlider()
    },
    getFile(data) {
      this.slider.image = data
    },
    handleChoice(value) {
      this.slider.choice = value
    },
    searchHandler(data) {
      this.search = data
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    autofillStartDate() {
      this.slider.start_at = new Date().toISOString().substr(0, 10)
    },
    autofillEndDate() {
      this.slider.end_at = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
    },
  },
}
</script>

<style>
.search > .v-input__control > .v-text-field__details {
  display: none;
}
.text-delete {
  color: #e30000 !important;
}
.theme--light.v-skeleton-loader .v-skeleton-loader__image {
  height: 315px;
}
</style>
